<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Purchase Price</h2>
            </CCardHeader>
            <CCardBody>
                <!-- <div class="col-xs-6 text-light p-1">
                    <button id="add" class="btn btn-success" 
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div> -->

                <purchaseItemPrice-grid :key="gridReload" :editClick="editClick" />
                <purchaseItemPrice-form ref="PurchaseItemPriceForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import PurchaseItemPriceGrid from '../PurchaseItemPrice/Grid/PurchaseItemPriceGrid.vue';
import PurchaseItemPriceForm from '../PurchaseItemPrice/Component/PurchaeItemPriceForm.vue';

export default {
    name: 'PurchaseItemPrice',
    components: {
        'purchaseItemPrice-grid': PurchaseItemPriceGrid,        
        'purchaseItemPrice-form': PurchaseItemPriceForm,
    },
    mounted () {
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        editClick(purchasePriceData, view){
            this.$refs.PurchaseItemPriceForm.editClick(purchasePriceData, view);
        },
        onChange(){
            this.reload();
        },
        reload(){
            this.gridReload++;
        },
        
    }
}
</script>

<style scoped>
</style>