<template>
    <div>
        <input hidden type="text" class="form-control" id="ItemId" v-model="ItemId">
        <div class="modal fade" id="PurchaseItemPriceModal" tabindex="-1"
        aria-labelledby="PurchaseItemPriceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PurchaseItemPriceModalLabel">{{ModalTitle}}{{ItemCode}}</h4>
                        <a type="button" data-bs-dismiss="modal"
                        aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Kode Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="5">
                                    <CInput id="KodeItem" v-model="ItemCode" class="font-weight-bold" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Nama Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="5">
                                    <CInput id="NamaItem" v-model="ItemName" class="font-weight-bold" />
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Harga Pembelian Barang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="5">
                                    <CInput id="PurchasePrice" type="number" v-model="PurchasePrice" class="font-weight-bold"/>
                                    <label id="errorPurchasePrice" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>                            
                            <br>

                            <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>

                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import PurchaseItemPriceServices from '../Script/PurchaseItemPrice.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'PurchaseItemPriceForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',

            ItemId: '',
            ItemCode : '',
            ItemName : '',
            PurchasePrice : '',

            Error: 0,
            SaveType: '',
        }
    },
    mounted(){
    },
    methods: {
        editClick(purchasePriceData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            document.getElementById('KodeItem').readOnly = true;
            document.getElementById('NamaItem').readOnly = true;
            
            this.ModalTitle = 'Edit Purchase Item Price: '

            this.ItemId = purchasePriceData.item_id
            this.ItemCode = purchasePriceData.item_code;
            this.ItemName = purchasePriceData.item_name;
            this.PurchasePrice = purchasePriceData.purchase_price;

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Purchase Item Price: ';
            }

            window.$('#PurchaseItemPriceModal').modal('show');
        },
        async inputValidation() {
            $(".form-error").css("display", "none");

            if(this.PurchasePrice <= 0 || this.PurchasePrice == '' || this.PurchasePrice == null){
                this.$swal("Error", "Harga Pembelian Barang tidak boleh lebih kecil dari 1", "error");
                this.Error++;
            }
        
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                const purchaseItemPriceData = {
                    item_id : this.ItemId.toString(),
                    purchase_price : parseFloat(this.PurchasePrice),
                };

                var variables = {
                    id : this.ItemId.toString(),
                    data : purchaseItemPriceData
                }
                
                PurchaseItemPriceServices.editQuery(variables).then(res => {
                    this.$swal("Info", response.successUpdate, "success");
                    window.$('#PurchaseItemPriceModal').modal('hide');
                    this.$props.reload();
                }).catch(error => {
                    var errorMessage = globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
    }
}
</script>
